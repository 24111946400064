/* eslint-disable react/self-closing-comp */
import React from 'react';
// import './styles.css';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import arnor from '../../arnor.jpg';

const useStyles = makeStyles({
  root: {},

  pos: {},
  cardStyle: {},
  avatar: {
    marginLeft: 5,
    marginTop: 5,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  paper: {
    display: 'flex',
    '& p': {
      justify: 'center',
      textAlign: 'center',
      margin: 0,
    },
  },
});

const PlayerLineup = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar} alt="Arnór Rafn" src={arnor} />
        <h2 className={classes.title}>John Doe</h2>
      </Paper>
    </Grid>
  );
};

export default PlayerLineup;
