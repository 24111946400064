import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import GameWeekResults from './components/GameWeekResults';
import PlayerDetails from './components/PlayerDetails';
import NavBar from './components/NavBar';

import PlayerList from './components/PlayerList';
import RegisterGames from './components/RegisterGames';

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route path="/gameweeks/:slug">
            <GameWeekResults />
          </Route>
          <Route exact path="/players/">
            <PlayerList />
          </Route>
          <Route path="/players/:slug">
            <PlayerDetails />
          </Route>
          <Route path="/register-games">
            <RegisterGames />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
