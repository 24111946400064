/* eslint-disable import/prefer-default-export */

export const setLocalStorageExpiry = (data, expiration, tokenKey) => {
  const now = new Date();

  const auth = `${data.token_type} ${data.access_token}`;

  const item = {
    value: auth,
    expiry: now.getTime() + expiration,
  };

  localStorage.setItem(tokenKey, JSON.stringify(item));

  return auth;
};

export const getLocalStorageExpiry = async (tokenKey) => {
  const localTokenObj = localStorage.getItem(tokenKey);
  if (!localTokenObj) {
    return null;
  }

  const item = JSON.parse(localTokenObj);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(tokenKey);
    return null;
  }

  return item.value;
};

export const getReadableDate = (date, isYear) => {
  const months = [
    'janúar',
    'febrúar',
    'mars',
    'apríl',
    'maí',
    'júní',
    'júlí',
    'ágúst',
    'september',
    'október',
    'nóvember',
    'desember',
  ];

  const day = date.substr(8).replace(/^0+/, '');
  const month = months[parseInt(date.substr(5, 2).replace(/^0+/, ''), 10) - 1];
  const year = date.substr(0, 4);

  const readableDate = `${day}. ${month} ${year}`;

  return isYear ? readableDate : `${day}. ${month}`;
};
