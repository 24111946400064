/* eslint-disable prettier/prettier */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { apiRootUrl, apiBasicAuth } from '../../config';
import { getReadableDate } from '../../utils/helperFunctions';
import Table from '../Table';
import './styles.css';

const PlayerDetails = () => {
  const { slug } = useParams();
  const [playerDetails, setPlayerDetails] = useState({});

  const COLUMNS = useMemo(() => [
    {
      Header: 'Dagsetning',
      accessor: 'date',
      Cell: (row) => <div>{getReadableDate(row.value, false)}</div>,
    },
    {
      Header: 'Mörk liðs',
      accessor: 'team_goals',
    },
    {
      Header: 'Mörk leikmanns',
      accessor: 'goals',
    },
    {
      Header: 'Stig',
      accessor: 'points',
    },
  ]);

  useEffect(() => {
    async function fetchPlayerDetails() {
      const res = await fetch(`${apiRootUrl}/players/${slug}/stats`, {
        method: 'GET',
        headers: {
          Authorization: apiBasicAuth,
        },
      });
      const data = await res.json();

      setPlayerDetails(data);
    }

    fetchPlayerDetails();
  }, [slug]);

  return Object.prototype.hasOwnProperty.call(playerDetails, 'games') ? (
    <div>
      <div className="player-details">
        <img src={playerDetails.photo_url} alt={playerDetails.name} />
        <h3>{playerDetails.name}</h3>

        <table className="stats">
          <tbody>
            <tr>
              <th>Stig:</th>
              <td>{playerDetails.total_points}</td>
            </tr>
            <tr>
              <th>Mörk:</th>
              <td>{playerDetails.total_goals}</td>
            </tr>
            <tr>
              <th>Samanlögð liðsmörk:</th>
              <td>{playerDetails.total_team_goals}</td>
            </tr>
            {playerDetails.games && (
              <tr>
                <th>Hlutfall af markaskorun:</th>
                <td>
                  {`${(
                    (playerDetails.total_goals /
                      playerDetails.total_team_goals) *
                    100
                  ).toFixed(1)}%`}
                </td>
              </tr>
            )}
            {playerDetails.games && (
              <tr>
                <th>Fjöldi þriðjudagsbolta:</th>
                <td>{playerDetails.games.length}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {playerDetails.games && (
        <div className="all-stats">
          <Table
            tableData={playerDetails.games}
            columns={COLUMNS}
            isPlayerDetails
          />
        </div>
      )}
    </div>
  ) : null;
};

export default PlayerDetails;
