import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiRootUrl, apiBasicAuth } from '../../config';
import { getReadableDate } from '../../utils/helperFunctions';
import Team from '../Team';
import './styles.css';

const GameWeekResults = () => {
  const { slug } = useParams();
  const [gameWeekResults, setGameWeekResults] = useState({});

  useEffect(() => {
    async function fetchGameWeekResults() {
      const res = await fetch(`${apiRootUrl}/games/${slug}`, {
        method: 'GET',
        headers: {
          Authorization: apiBasicAuth,
        },
      });
      const data = await res.json();

      setGameWeekResults(data);
    }

    fetchGameWeekResults();
  }, [slug]);

  let teams;
  let date;
  if (gameWeekResults.teams) {
    date = getReadableDate(gameWeekResults.date, true);
    const sortedTeams = gameWeekResults.teams.sort(
      (a, b) => b.points - a.points
    );
    teams = sortedTeams.map((team) => (
      <div className="team" key={team.color}>
        <Team team={team} />
      </div>
    ));
  }

  return (
    <div>
      <h2 className="date">{date}</h2>
      <div className="team-container">{teams}</div>
    </div>
  );
};

export default GameWeekResults;
