/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Team = ({ team }) => {
  const { players, color, total_goals, points } = team;
  const sortedPlayers = players.sort((a, b) => b.goals - a.goals);

  const playerTable = sortedPlayers.map((player, idx) => (
    <tr key={idx}>
      <th>{player.name.split(' ').slice(0, -1).join(' ')}</th>
      <td>{player.goals}</td>
    </tr>
  ));
  let dot;

  if (color === 'white') {
    dot = '#fff';
  } else if (color === 'red') {
    dot = '#d12e2e';
  } else if (color === 'blue') {
    dot = '#2733e3';
  } else {
    dot = '#303033';
  }

  return (
    <div className="team-box">
      <span className="color-dot" style={{ backgroundColor: dot }} />
      <table className="team-stats">
        <thead>
          <tr>
            <th>Nafn</th>
            <th>Mörk</th>
          </tr>
        </thead>

        <tbody>
          {playerTable}
          <tr className="total-goals">
            <th>Samtals mörk :</th>
            <td>{total_goals}</td>
          </tr>
          <tr>
            <th>Stig:</th>
            <td>{points}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

Team.propTypes = {
  team: PropTypes.shape({
    players: PropTypes.arrayOf(PropTypes.object).isRequired,
    color: PropTypes.string.isRequired,
    total_goals: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
  }).isRequired,
};

export default Team;
