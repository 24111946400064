import React from 'react';
import './styles.css';
import GameWeeks from '../GameWeeks';
import Standings from '../Standings';

const Dashboard = () => (
  <div className="dashboard">
    <Standings />
    <GameWeeks />
  </div>
);

export default Dashboard;
