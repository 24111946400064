import React from 'react';
import { Link } from 'react-router-dom';
// import logo from '../../icons/logo.svg';
import './styles.css';

const NavBar = () => (
  <nav className="navbar">
    <div className="nav-container">
      <div className="nav-left">
        <Link to="/">
          <p className="nav-logo-text">Bubbabolti</p>
        </Link>
      </div>
    </div>
  </nav>
);

export default NavBar;
