import React, { useState, useEffect, useMemo } from 'react';
import { apiBasicAuth, apiRootUrl } from '../../config';
// import { retrieveToken } from '../../utils/tokenService';
import { getReadableDate } from '../../utils/helperFunctions';
import Table from '../Table';
import './styles.css';

const GameWeeks = () => {
  const COLUMNS = useMemo(() => [
    {
      Header: 'Dagsetning bolta',
      accessor: 'date',
      Cell: (row) => <div>{getReadableDate(row.value, true)}</div>,
    },
  ]);
  const [gameWeeks, setGameweeks] = useState([]);

  useEffect(() => {
    async function fetchGameweeks() {
      // const token = await retrieveToken();
      const res = await fetch(`${apiRootUrl}/games/seasons/2024H2/dates`, {
        method: 'GET',
        headers: {
          Authorization: apiBasicAuth,
        },
      });
      const data = await res.json();

      setGameweeks(data);
    }

    fetchGameweeks();
  }, []);

  return <Table tableData={gameWeeks} columns={COLUMNS} isGames />;
};

export default GameWeeks;
