/* eslint-disable react/no-children-prop */
/* eslint-disable no-return-assign */
import React from 'react';
import './styles.css';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Timer from '../Timer';
import PlayerLineup from '../PlayerLineup';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: { padding: theme.spacing(2), textAlign: 'center' },
  clockContainer: { padding: 20 },
  teamContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  },
}));

const RegisterGames = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.clockContainer}>
        <Grid container justify="center" xs={12}>
          <Grid item xs={12} sm={6}>
            <Timer />
          </Grid>
        </Grid>
        <Grid
          container
          xs={6}
          className={classes.teamContainer}
          direction="column"
        >
          <Grid container item spacing={3}>
            <PlayerLineup />
            <PlayerLineup />
            <PlayerLineup />
            <PlayerLineup />
            <PlayerLineup />
          </Grid>
        </Grid>
        <Grid
          container
          xs={6}
          className={classes.teamContainer}
          direction="column"
        >
          <Grid container item spacing={3}>
            <PlayerLineup />
            <PlayerLineup />
            <PlayerLineup />
            <PlayerLineup />
            <PlayerLineup />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegisterGames;
