/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import PropTypes from 'prop-types';
import './styles.css';

const Table = (props) => {
  const { columns, tableData, isGames, isPlayerDetails } = props;
  const data = useMemo(() => tableData, [tableData]);

  const history = useHistory();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        sortBy: [
          {
            id: 'total_points',
            desc: true,
          },
          {
            id: 'date',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );
  return (
    <table {...getTableProps()} className="styled-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className={
                  !isGames && !isPlayerDetails
                    ? 'table-standings-header'
                    : isPlayerDetails
                    ? 'table-details-header'
                    : ''
                }
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}

                {column.isSorted && !isGames
                  ? column.isSortedDesc
                    ? ' ↓'
                    : ' ↑'
                  : ''}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              onClick={() =>
                isGames
                  ? history.push(`/gameweeks/${row.original._id}`)
                  : isPlayerDetails
                  ? history.push(`/gameweeks/${row.original.game_id}`)
                  : history.push(`/players/${row.original._id}`)
              }
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  isGames: PropTypes.bool,
  isPlayerDetails: PropTypes.bool,
};

Table.defaultProps = {
  isGames: false,
  isPlayerDetails: false,
};

export default Table;
