import React, { useState, useEffect } from 'react';
import { apiRootUrl, apiBasicAuth } from '../../config';
import PlayerBox from '../PlayerBox';
import './styles.css';

const PlayerList = () => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    async function fetchGameweeks() {
      const res = await fetch(`${apiRootUrl}/players`, {
        method: 'GET',
        headers: {
          Authorization: apiBasicAuth,
        },
      });
      const data = await res.json();
      setPlayers(data);
    }

    fetchGameweeks();
  }, []);

  const playerArray = players.map((player) => (
    <div>
      <PlayerBox player={player} />
    </div>
  ));

  return (
    <div>
      <div className="box-container">{playerArray}</div>
      <button type="submit">Add player</button>
    </div>
  );
};

export default PlayerList;
