/* eslint-disable react/prop-types */
import React from 'react';
import './styles.css';
import Avatar from '@material-ui/core/Avatar';
import arnor from '../../arnor.jpg';

const PlayerBox = ({ player }) => (
  <div className="player-box">
    <Avatar alt="Arnór Rafn" src={arnor} />
    <h3>{player.name}</h3>
    <p>{player.total_games}</p>
    <p>{player.total_goals}</p>
    <p>{player.total_points}</p>
  </div>
);

export default PlayerBox;
