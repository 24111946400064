/* eslint-disable react/jsx-one-expression-per-line */
import React, { useRef, useState } from 'react';
import './styles.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Countdown, { zeroPad } from 'react-countdown';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardStyle: {
    justifyContent: 'center',
  },
}));

let refApi;

const renderer = ({ minutes, seconds, api }) => {
  refApi = api;
  return (
    <span className="clock">
      {minutes}:{zeroPad(seconds)}
    </span>
  );
};

const Timer = () => {
  const [timerKey, setTimerKey] = useState(1);
  // const [isPaused, setIsPaused] = useState(true);
  const classes = useStyles();
  const timerRef = useRef(null);
  const pause = () => {
    refApi.pause();
    // setIsPaused(true);
  };

  const resume = () => {
    refApi.start();
    // setIsPaused(false);
  };

  const goal = () => {
    refApi.pause();

    const newKey = timerKey + 1;
    setTimerKey(newKey);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Countdown
          autoStart={false}
          renderer={renderer}
          date={Date.now() + 150000}
          key={timerKey}
          ref={timerRef}
        />
      </CardContent>
      <CardActions className={classes.cardStyle}>
        <Button variant="contained" color="primary" onClick={() => resume()}>
          Start
        </Button>
        <Button variant="contained" color="primary" onClick={() => pause()}>
          Pause
        </Button>
        <Button variant="contained" color="secondary" onClick={() => goal()}>
          Goal
        </Button>
      </CardActions>
    </Card>
  );
};

export default Timer;
